import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { listIcon } from '../../ui/icons/business-objects';
import { SearchResult, SearchResultAttributes, SearchResultProps } from '../search/search-result';

export default function ListSearchResult({ result, onClick }: SearchResultProps) {
  invariant(result.resultType === 'LIST', `${result.resultType} is no instance of ListSearchResult`);

  const list = result.result;
  const { t } = useTranslation(['list', 'common']);

  return (
    <SearchResult
      icon={listIcon}
      title={list.name}
      link={`/lists/${list.id}`}
      score={result.score}
      onClick={onClick}
      necessaryPermission="LIST:CAN_SEE_DETAILS"
    >
      <SearchResultAttributes
        attributes={[
          {
            label: t('list:name'),
            element: list.name,
          },
        ]}
      />
    </SearchResult>
  );
}
