import {
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useId,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { DefaultValues, FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { v4 as randomUUID } from 'uuid';
import {
  EmailAddressDto,
  EmailAddressDtoLabelEnum,
  OccupationDtoConnectionTypeEnum,
  OccupationDtoStatusEnum,
  PersonDto,
  PersonReferenceDto,
  PersonStatusDto,
  SimplePersonDto,
} from '../../../api';
import personApi from '../../../data-access/person-api';
import useAccordionState from '../../../ui/accordion/use-accordion-state';
import Form from '../../../ui/form/form';
import useAccordionForm from '../../../ui/form/use-accordion-form/use-accordion-form';
import { PageSpinner } from '../../../ui/page';
import CustomTab from '../../../ui/tab/custom-tab';
import useToast from '../../../ui/use-toast/use-toast';
import usePrompt from '../../../util/use-prompt/use-prompt';
import { LayoutType } from '../../common/LayoutType';
import { DuplicatesProvider, useDuplicateWarning } from '../person-form/duplicate-warning';
import { PersonFormModel } from '../person-form/person-form';
import PersonInnerForm from '../person-form/person-inner-form';
import usePersonFormAccordionItems from '../person-form/use-person-form-accordion-items';
import useHandleValid from '../person-form/useHandleValid';
import PersonSimpleForm from './person-simple-form';
import PersonSimpleFormModel, { SimpleOccupation } from './person-simple-form-model';
import useSimpleHandleValid from './use-simple-handle-valid';

interface DrawerFormProps {
  templatePerson: DefaultValues<PersonDto>;
  isOpen: boolean;
  onSave?(person: PersonReferenceDto): void;
  onClose(): void;
}

export default function PersonDrawerForm({ templatePerson, isOpen, onClose, onSave }: DrawerFormProps) {
  const { t } = useTranslation(['person', 'common']);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [showDuplicateInfo, setShowDuplicateInfo] = React.useState(false);
  const [duplicates, setDuplicates] = React.useState<SimplePersonDto[]>();
  const [isSearchingDuplicates, setIsSearchingDuplicates] = React.useState(false);
  const fullFormId = useId();
  const simpleFormId = useId();

  const fullForm = useForm<PersonDto>({
    mode: 'all',
    defaultValues: templatePerson,
  });

  const simpleForm = useForm<PersonSimpleFormModel>({
    mode: 'all',
    defaultValues: {
      firstName: '',
      surname: '',
      email: null,
      language: templatePerson.contactLanguage,
      dateOfBirth: null,
      occupation: [],
      initialComment: null,
      status: PersonStatusDto.ACTIVE,
    },
  });

  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const showSuccessToast = useToast({
    id: 'person-editor-success-toast',
    status: 'success',
  });

  const handlePersonSave = async (personDto: PersonDto) => {
    showSuccessToast.closeAll();
    cleanupPersonDto(personDto);

    const { id, personKey, firstName, surname } = await personApi.createPerson({ personDto });
    invariant(id != null, 'Person ID missing');

    showSuccessToast({
      title: t('person:editor.toast.success.title'),
      description: t('person:editor.toast.success.description', { personKey, firstName, surname }),
    });

    onSave?.({ id, personKey, firstName, surname });
    await resetAndClose();
  };

  const { handleValid, confirmDialog } = useHandleValid({
    form: fullForm as UseFormReturn<PersonFormModel>,
    onPersonSave: handlePersonSave,
  });

  const { handleValid: simpleHandleValid, confirmDialog: simpleConfirmDialog } = useSimpleHandleValid({
    form: simpleForm,
    templatePerson,
    onPersonSave: handlePersonSave,
  });

  const simpleFormIsDirty = Object.keys(simpleForm.formState.dirtyFields).length > 0;
  const fullFormIsDirty = Object.keys(fullForm.formState.dirtyFields).length > 0;

  // only used in the full form tab, simple form tab uses its own method to search for duplicates
  const { duplicatesDto, searchingDuplicates, resetDuplicates } = useDuplicateWarning(fullForm);

  const resetAndClose = useCallback(async () => {
    if (
      (!simpleFormIsDirty && !fullFormIsDirty) ||
      simpleForm.formState.isSubmitting ||
      fullForm.formState.isSubmitting ||
      window.confirm(t('common:misc.unsaved_changes'))
    ) {
      fullForm.reset();
      simpleForm.reset();
      setShowDuplicateInfo(false);
      setDuplicates(undefined);
      resetDuplicates();
      onClose();
    }
  }, [simpleFormIsDirty, fullFormIsDirty, simpleForm, fullForm, t, resetDuplicates, onClose]);

  usePrompt({ message: t('common:misc.unsaved_changes'), when: simpleFormIsDirty || fullFormIsDirty });

  function handleTabChange(index: number) {
    setSelectedTab(index);

    if (index === 0) {
      transferToSimpleForm(simpleForm, fullForm);
    }
    if (index === 1) {
      transferToFullForm(simpleForm, fullForm);
    }
  }

  const accordionItems = usePersonFormAccordionItems({
    layout: LayoutType.SMALL,
    showInitialComment: true,
    disableStatus: true,
  });
  const [expandedIndices, setExpandedIndices] = useAccordionState(accordionItems);
  const { handleInvalid } = useAccordionForm(accordionItems, setExpandedIndices);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={resetAndClose} size="sm">
      <DrawerOverlay />
      <DrawerContent overflow="auto" h="100%">
        <DrawerCloseButton />
        <DrawerHeader>{t('person:action.new')}</DrawerHeader>

        <DrawerBody pt={4} p={6}>
          <React.Suspense fallback={<PageSpinner />}>
            <Tabs variant="unstyled" isLazy tabIndex={selectedTab} onChange={handleTabChange}>
              <TabList>
                <CustomTab left>{t('person:editor.simple')}</CustomTab>
                <CustomTab right>{t('person:editor.extended')}</CustomTab>
              </TabList>
              <TabPanels>
                <TabPanel p={0} mt={4}>
                  <FormProvider {...simpleForm}>
                    <Form<PersonSimpleFormModel>
                      id={simpleFormId}
                      onValid={simpleHandleValid}
                      initialFocusRef={initialFocusRef}
                    >
                      <PersonSimpleForm
                        duplicates={duplicates}
                        setDuplicates={setDuplicates}
                        showDuplicateInfo={showDuplicateInfo}
                        setShowDuplicateInfo={setShowDuplicateInfo}
                        setIsSearchingDuplicates={setIsSearchingDuplicates}
                        initialFocusRef={initialFocusRef}
                      />
                    </Form>
                    {simpleConfirmDialog}
                  </FormProvider>
                </TabPanel>

                <TabPanel p={0} mt={4}>
                  <FormProvider {...fullForm}>
                    <Form<PersonDto>
                      id={fullFormId}
                      onValid={handleValid}
                      onInvalid={handleInvalid}
                      initialFocusRef={initialFocusRef}
                    >
                      <DuplicatesProvider value={duplicatesDto}>
                        <PersonInnerForm
                          layout={LayoutType.SMALL}
                          accordionItems={accordionItems}
                          expandedIndices={expandedIndices}
                          setExpandedIndices={setExpandedIndices}
                          initialFocusRef={initialFocusRef}
                        />
                      </DuplicatesProvider>
                    </Form>
                    {confirmDialog}
                  </FormProvider>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </React.Suspense>
        </DrawerBody>
        <DrawerFooter>
          <ButtonGroup justifyContent="space-between" w="full">
            <Button variant="solid" onClick={resetAndClose}>
              {t('common:action.abort')}
            </Button>

            {selectedTab === 0 ? (
              <Button
                type="submit"
                form={simpleFormId}
                variant="primary"
                isLoading={isSearchingDuplicates}
                loadingText={isSearchingDuplicates ? t('person:editor.search_duplicates') : t('person:editor.saving')}
                isDisabled={!simpleFormIsDirty}
              >
                {t('common:action.proof_and_save')}
              </Button>
            ) : (
              <Button
                type="submit"
                form={fullFormId}
                variant="primary"
                isLoading={searchingDuplicates}
                loadingText={searchingDuplicates ? t('person:editor.search_duplicates') : t('person:editor.saving')}
                isDisabled={!fullFormIsDirty}
              >
                {t('common:action.proof_and_save')}
              </Button>
            )}
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

// convert null to undefined in dates for date picker
function cleanupPersonDto(personDto: PersonDto) {
  personDto.dateOfBirth = personDto.dateOfBirth || undefined;
  personDto.personPersonConnections?.forEach(
    (connection) => (connection.dateRange.end = connection.dateRange.end || undefined),
  );
  personDto.occupations?.forEach((value) => (value.formats = []));
}

function transferToFullForm(simpleForm: UseFormReturn<PersonSimpleFormModel>, fullForm: UseFormReturn<PersonDto>) {
  if (simpleFormFieldHasValueAndValid(simpleForm, 'firstName')) {
    fullForm.setValue('firstName', simpleForm.getValues('firstName'), { shouldDirty: true });
  }

  if (simpleFormFieldHasValueAndValid(simpleForm, 'surname')) {
    fullForm.setValue('surname', simpleForm.getValues('surname'), { shouldDirty: true });
  }

  if (simpleFormFieldHasValueAndValid(simpleForm, 'dateOfBirth')) {
    fullForm.setValue('dateOfBirth', simpleForm.getValues('dateOfBirth') as Date | undefined, {
      shouldDirty: true,
    });
  }

  if (simpleFormFieldHasValueAndValid(simpleForm, 'language')) {
    fullForm.setValue('contactLanguage', simpleForm.getValues('language'), { shouldDirty: true });
  }

  const email = simpleForm.getValues('email');
  if (email != null && email !== '' && !simpleForm.getFieldState('email').error) {
    fullForm.setValue('emailAddresses.0.email', email, { shouldDirty: true });
    fullForm.setValue('emailAddresses.0.label', EmailAddressDtoLabelEnum.CONTACT);
  } else {
    fullForm.setValue('emailAddresses', undefined);
  }

  const occupation = simpleForm.getValues('occupation');
  if (isOccupationValid(occupation)) {
    fullForm.setValue('occupations.0.id', occupation![0].id);
    fullForm.setValue('occupations.0.jobTitle', occupation![0].jobTitle, { shouldDirty: true });
    fullForm.setValue('occupations.0.profession', occupation![0].profession);
    fullForm.setValue('occupations.0.workingSectors', occupation![0].workingSectors);
    fullForm.setValue('occupations.0.connectedCompany', occupation![0].company);
    fullForm.setValue('occupations.0.dateRange', { start: new Date(), end: undefined });
    fullForm.setValue('occupations.0.mainActivity', true);
    fullForm.setValue('occupations.0.status', OccupationDtoStatusEnum.CONFIRMED);
    fullForm.setValue(
      'occupations.0.connectionType',
      occupation![0].company ? OccupationDtoConnectionTypeEnum.EMPLOYEE : OccupationDtoConnectionTypeEnum.FREELANCER,
    );
    fullForm.setValue('occupations.0.formats', []);
  } else {
    fullForm.setValue('occupations', []);
  }

  if (simpleFormFieldHasValueAndValid(simpleForm, 'initialComment')) {
    fullForm.setValue('initialComment', simpleForm.getValues('initialComment') as string | undefined, {
      shouldDirty: true,
    });
  }
}

function transferToSimpleForm(simpleForm: UseFormReturn<PersonSimpleFormModel>, fullForm: UseFormReturn<PersonDto>) {
  if (fullFormFieldHasValueAndValid(fullForm, 'firstName')) {
    simpleForm.setValue('firstName', fullForm.getValues('firstName'), { shouldDirty: true });
  }

  if (fullFormFieldHasValueAndValid(fullForm, 'surname')) {
    simpleForm.setValue('surname', fullForm.getValues('surname'), { shouldDirty: true });
  }

  if (fullFormFieldHasValueAndValid(fullForm, 'dateOfBirth')) {
    simpleForm.setValue('dateOfBirth', fullForm.getValues('dateOfBirth') as Date | null, { shouldDirty: true });
  }

  if (fullFormFieldHasValueAndValid(fullForm, 'contactLanguage')) {
    simpleForm.setValue('language', fullForm.getValues('contactLanguage'), { shouldDirty: true });
  }

  const emails: EmailAddressDto[] | undefined = fullForm.getValues('emailAddresses');
  if (emails != null && emails.length > 0) {
    simpleForm.setValue('email', emails[0].email, { shouldDirty: true });
  }

  const occupations = fullForm.getValues('occupations');
  if (occupations != null && occupations.some((o) => o.mainActivity)) {
    const mainOccupation = occupations.filter((o) => o.mainActivity);
    simpleForm.setValue('occupation.0.id', mainOccupation[0].id ?? randomUUID());
    simpleForm.setValue('occupation.0.jobTitle', mainOccupation[0].jobTitle);
    simpleForm.setValue('occupation.0.profession', mainOccupation[0].profession);
    simpleForm.setValue('occupation.0.workingSectors', mainOccupation[0].workingSectors);
    simpleForm.setValue('occupation.0.company', mainOccupation[0].connectedCompany);
  }

  if (fullFormFieldHasValueAndValid(fullForm, 'initialComment')) {
    simpleForm.setValue('initialComment', fullForm.getValues('initialComment') as string | null, { shouldDirty: true });
  }
}

function isOccupationValid(occupation: SimpleOccupation[] | undefined): boolean {
  return (
    occupation != null &&
    occupation.length === 1 &&
    occupation[0].jobTitle !== '' &&
    occupation[0].workingSectors != null &&
    occupation[0].profession != null
  );
}

function simpleFormFieldHasValueAndValid(form: UseFormReturn<PersonSimpleFormModel>, field: any) {
  return form.getFieldState(field).isDirty && !form.getFieldState(field).error;
}

function fullFormFieldHasValueAndValid(form: UseFormReturn<PersonDto>, field: any) {
  return form.getFieldState(field).isDirty && !form.getFieldState(field).error;
}
