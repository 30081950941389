import React from 'react';
import { formatIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useFormatPermissionClassGroupExtension } from './format-permissions';
import FormatSearchResult from './format-search-result';

export default function FormatPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'FORMAT', Component: FormatSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'FORMAT',
    icon: formatIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.FORMAT" />,
  });
  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useFormatPermissionClassGroupExtension);
}
