import React from 'react';
import { FieldValues } from 'react-hook-form';
import { FieldPathByValue } from 'react-hook-form/dist/types/path/eager';
import { EventReferenceDto, EventStatusDto } from '../../../api';
import eventApi from '../../../data-access/event-api';
import ValueAsyncSelectFormControl, {
  ValueAsyncSelectFormControlProps,
} from '../../../ui/form/select-control/value-async-select-form-control';

interface EventSelectControlProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, EventReferenceDto>,
> extends Omit<
    ValueAsyncSelectFormControlProps<EventReferenceDto, TFieldValues, TName>,
    'children' | 'filter' | 'renderLabel' | 'optionIdentifier' | 'loadOptions'
  > {
  label: string;
  filter?: string[];
  loadOptions?: (q: string, size: number) => Promise<EventReferenceDto[]>;
}

export default function EventSelectControl<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, EventReferenceDto>,
>({
  isRequired,
  name,
  label,
  filter = [`status,!eq,${EventStatusDto.CANCELLED}`],
  loadOptions = async (q: string, size: number) => {
    const page = await eventApi.searchEventReferences({
      searchQuery: q,
      pageable: { size, sort: ['title,ASC'] },
      filter,
    });

    return page.content.map((event) => ({
      id: event.id!,
      title: event.title,
      edition: event.edition,
      status: event.status,
    }));
  },
  ...props
}: EventSelectControlProps<TFieldValues, TName>) {
  return (
    <ValueAsyncSelectFormControl<EventReferenceDto, TFieldValues, TName>
      name={name}
      isRequired={isRequired}
      label={label}
      renderLabel={(event) => event.title}
      optionIdentifier={(event) => event.id}
      loadOptions={loadOptions}
      defaultOptions={false}
      {...props}
    />
  );
}
