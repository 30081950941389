import { MenuItem } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDto } from '../../../api';
import companyApi from '../../../data-access/company-api';
import DeleteButton from '../../../ui/action-menu/delete-button/delete-button';
import usePermission from '../../permission/use-permission';

export default function useCompanyDeleteActions() {
  const { t } = useTranslation(['company']);
  const { hasPermission } = usePermission();

  return useCallback(
    (company: CompanyDto, isDeletable: boolean, afterDelete?: () => void) => {
      const buttonList: ReactNode[] = [];

      if (hasPermission('COMPANY:CAN_DELETE')) {
        buttonList.push(
          <MenuItem
            isDisabled={!isDeletable}
            disableReason={t('action.deleteFailedReason')}
            color="text.error"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            as={DeleteButton<CompanyDto>}
            businessObject={company}
            objectName={company.name}
            deleteObject={({ id }: CompanyDto) => companyApi.deleteCompany({ id })}
            objectTypeToDelete={t('company')}
            afterDelete={afterDelete}
            objectTypeWithArticleNominative={t('companyWithArticle')}
            objectTypeWithArticleGenitive={t('company_with_article_genetive')}
          >
            {t('action.delete')}
          </MenuItem>,
        );
      }
      return buttonList;
    },
    [hasPermission, t],
  );
}
