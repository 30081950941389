/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClosedDateTimeRangeDto } from './ClosedDateTimeRangeDto';
import {
    ClosedDateTimeRangeDtoFromJSON,
    ClosedDateTimeRangeDtoFromJSONTyped,
    ClosedDateTimeRangeDtoToJSON,
} from './ClosedDateTimeRangeDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { EventAccessControlDto } from './EventAccessControlDto';
import {
    EventAccessControlDtoFromJSON,
    EventAccessControlDtoFromJSONTyped,
    EventAccessControlDtoToJSON,
} from './EventAccessControlDto';
import type { EventStatusDto } from './EventStatusDto';
import {
    EventStatusDtoFromJSON,
    EventStatusDtoFromJSONTyped,
    EventStatusDtoToJSON,
} from './EventStatusDto';
import type { EventTypeDto } from './EventTypeDto';
import {
    EventTypeDtoFromJSON,
    EventTypeDtoFromJSONTyped,
    EventTypeDtoToJSON,
} from './EventTypeDto';
import type { EventVenueDto } from './EventVenueDto';
import {
    EventVenueDtoFromJSON,
    EventVenueDtoFromJSONTyped,
    EventVenueDtoToJSON,
} from './EventVenueDto';
import type { GuestListReferenceDto } from './GuestListReferenceDto';
import {
    GuestListReferenceDtoFromJSON,
    GuestListReferenceDtoFromJSONTyped,
    GuestListReferenceDtoToJSON,
} from './GuestListReferenceDto';
import type { PermissionsDto } from './PermissionsDto';
import {
    PermissionsDtoFromJSON,
    PermissionsDtoFromJSONTyped,
    PermissionsDtoToJSON,
} from './PermissionsDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * List item for events
 * @export
 * @interface EventListItemDto
 */
export interface EventListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof EventListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EventListItemDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof EventListItemDto
     */
    englishTitle: string;
    /**
     * 
     * @type {ClosedDateTimeRangeDto}
     * @memberof EventListItemDto
     */
    dateTimeRange: ClosedDateTimeRangeDto;
    /**
     * 
     * @type {EventVenueDto}
     * @memberof EventListItemDto
     */
    venue: EventVenueDto;
    /**
     * 
     * @type {EventTypeDto}
     * @memberof EventListItemDto
     */
    type: EventTypeDto;
    /**
     * 
     * @type {GuestListReferenceDto}
     * @memberof EventListItemDto
     */
    guestList: GuestListReferenceDto;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof EventListItemDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {Array<EventAccessControlDto>}
     * @memberof EventListItemDto
     */
    accessControls: Array<EventAccessControlDto>;
    /**
     * 
     * @type {EventStatusDto}
     * @memberof EventListItemDto
     */
    status: EventStatusDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof EventListItemDto
     */
    version: VersionDto;
    /**
     * 
     * @type {PermissionsDto}
     * @memberof EventListItemDto
     */
    permissions: PermissionsDto;
}

/**
 * Check if a given object implements the EventListItemDto interface.
 */
export function instanceOfEventListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('englishTitle' in value)) return false;
    if (!('dateTimeRange' in value)) return false;
    if (!('venue' in value)) return false;
    if (!('type' in value)) return false;
    if (!('guestList' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('accessControls' in value)) return false;
    if (!('status' in value)) return false;
    if (!('version' in value)) return false;
    if (!('permissions' in value)) return false;
    return true;
}

export function EventListItemDtoFromJSON(json: any): EventListItemDto {
    return EventListItemDtoFromJSONTyped(json, false);
}

export function EventListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'englishTitle': json['englishTitle'],
        'dateTimeRange': ClosedDateTimeRangeDtoFromJSON(json['dateTimeRange']),
        'venue': EventVenueDtoFromJSON(json['venue']),
        'type': EventTypeDtoFromJSON(json['type']),
        'guestList': GuestListReferenceDtoFromJSON(json['guestList']),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'accessControls': ((json['accessControls'] as Array<any>).map(EventAccessControlDtoFromJSON)),
        'status': EventStatusDtoFromJSON(json['status']),
        'version': VersionDtoFromJSON(json['version']),
        'permissions': PermissionsDtoFromJSON(json['permissions']),
    };
}

export function EventListItemDtoToJSON(value?: EventListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'englishTitle': value['englishTitle'],
        'dateTimeRange': ClosedDateTimeRangeDtoToJSON(value['dateTimeRange']),
        'venue': EventVenueDtoToJSON(value['venue']),
        'type': EventTypeDtoToJSON(value['type']),
        'guestList': GuestListReferenceDtoToJSON(value['guestList']),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'accessControls': ((value['accessControls'] as Array<any>).map(EventAccessControlDtoToJSON)),
        'status': EventStatusDtoToJSON(value['status']),
        'version': VersionDtoToJSON(value['version']),
        'permissions': PermissionsDtoToJSON(value['permissions']),
    };
}

