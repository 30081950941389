/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyEmailAddressDto } from './CompanyEmailAddressDto';
import {
    CompanyEmailAddressDtoFromJSON,
    CompanyEmailAddressDtoFromJSONTyped,
    CompanyEmailAddressDtoToJSON,
} from './CompanyEmailAddressDto';
import type { CompanyMarkerDto } from './CompanyMarkerDto';
import {
    CompanyMarkerDtoFromJSON,
    CompanyMarkerDtoFromJSONTyped,
    CompanyMarkerDtoToJSON,
} from './CompanyMarkerDto';
import type { CompanyPhoneNumberDto } from './CompanyPhoneNumberDto';
import {
    CompanyPhoneNumberDtoFromJSON,
    CompanyPhoneNumberDtoFromJSONTyped,
    CompanyPhoneNumberDtoToJSON,
} from './CompanyPhoneNumberDto';
import type { CompanyPostalAddressDto } from './CompanyPostalAddressDto';
import {
    CompanyPostalAddressDtoFromJSON,
    CompanyPostalAddressDtoFromJSONTyped,
    CompanyPostalAddressDtoToJSON,
} from './CompanyPostalAddressDto';
import type { CompanyPublicationNameDto } from './CompanyPublicationNameDto';
import {
    CompanyPublicationNameDtoFromJSON,
    CompanyPublicationNameDtoFromJSONTyped,
    CompanyPublicationNameDtoToJSON,
} from './CompanyPublicationNameDto';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { CompanySectionDto } from './CompanySectionDto';
import {
    CompanySectionDtoFromJSON,
    CompanySectionDtoFromJSONTyped,
    CompanySectionDtoToJSON,
} from './CompanySectionDto';
import type { CountryReferenceDto } from './CountryReferenceDto';
import {
    CountryReferenceDtoFromJSON,
    CountryReferenceDtoFromJSONTyped,
    CountryReferenceDtoToJSON,
} from './CountryReferenceDto';
import type { FieldOfInterestDto } from './FieldOfInterestDto';
import {
    FieldOfInterestDtoFromJSON,
    FieldOfInterestDtoFromJSONTyped,
    FieldOfInterestDtoToJSON,
} from './FieldOfInterestDto';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { FilmographyDto } from './FilmographyDto';
import {
    FilmographyDtoFromJSON,
    FilmographyDtoFromJSONTyped,
    FilmographyDtoToJSON,
} from './FilmographyDto';
import type { IndustryDto } from './IndustryDto';
import {
    IndustryDtoFromJSON,
    IndustryDtoFromJSONTyped,
    IndustryDtoToJSON,
} from './IndustryDto';
import type { TaxExemptionDto } from './TaxExemptionDto';
import {
    TaxExemptionDtoFromJSON,
    TaxExemptionDtoFromJSONTyped,
    TaxExemptionDtoToJSON,
} from './TaxExemptionDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * An industry-specific company.
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof CompanyDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof CompanyDto
     */
    version?: VersionDto;
    /**
     * Name of a company
     * @type {string}
     * @memberof CompanyDto
     */
    name: string;
    /**
     * List of synonyms of the company.
     * @type {Array<string>}
     * @memberof CompanyDto
     */
    synonyms?: Array<string>;
    /**
     * The publication names of the company.
     * @type {Array<CompanyPublicationNameDto>}
     * @memberof CompanyDto
     */
    namesForPublication?: Array<CompanyPublicationNameDto>;
    /**
     * List of email addresses
     * @type {Array<CompanyEmailAddressDto>}
     * @memberof CompanyDto
     */
    emailAddresses?: Array<CompanyEmailAddressDto>;
    /**
     * List of phone numbers
     * @type {Array<CompanyPhoneNumberDto>}
     * @memberof CompanyDto
     */
    phoneNumbers?: Array<CompanyPhoneNumberDto>;
    /**
     * List of postal addresses
     * @type {Array<CompanyPostalAddressDto>}
     * @memberof CompanyDto
     */
    postalAddresses?: Array<CompanyPostalAddressDto>;
    /**
     * List of company sections with own addresses
     * @type {Array<CompanySectionDto>}
     * @memberof CompanyDto
     */
    companySections?: Array<CompanySectionDto>;
    /**
     * 
     * @type {Array<CountryReferenceDto>}
     * @memberof CompanyDto
     */
    countries?: Array<CountryReferenceDto>;
    /**
     * List of fields of interest of the company, at the moment same as of person but may differ in the future
     * @type {Array<FieldOfInterestDto>}
     * @memberof CompanyDto
     */
    fieldsOfInterest?: Array<FieldOfInterestDto>;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    detailedDescription?: string;
    /**
     * 
     * @type {Array<TaxExemptionDto>}
     * @memberof CompanyDto
     */
    taxExemptions: Array<TaxExemptionDto>;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    status: CompanyDtoStatusEnum;
    /**
     * List of industries, within the company operates.
     * @type {Array<IndustryDto>}
     * @memberof CompanyDto
     */
    industries?: Array<IndustryDto>;
    /**
     * 
     * @type {Array<FilmographyDto>}
     * @memberof CompanyDto
     */
    filmographies?: Array<FilmographyDto>;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof CompanyDto
     */
    logoMetadata?: FileMetadataDto;
    /**
     * List of markers for the company
     * @type {Array<CompanyMarkerDto>}
     * @memberof CompanyDto
     */
    markers?: Array<CompanyMarkerDto>;
    /**
     * List of additional historic markers for the company
     * @type {Array<CompanyMarkerDto>}
     * @memberof CompanyDto
     */
    historicMarkers?: Array<CompanyMarkerDto>;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof CompanyDto
     */
    mergedInto?: CompanyReferenceDto;
}


/**
 * @export
 */
export const CompanyDtoStatusEnum = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    HISTORIC: 'HISTORIC',
    UNCONFIRMED: 'UNCONFIRMED',
    ARCHIVED: 'ARCHIVED'
} as const;
export type CompanyDtoStatusEnum = typeof CompanyDtoStatusEnum[keyof typeof CompanyDtoStatusEnum];


/**
 * Check if a given object implements the CompanyDto interface.
 */
export function instanceOfCompanyDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('taxExemptions' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function CompanyDtoFromJSON(json: any): CompanyDto {
    return CompanyDtoFromJSONTyped(json, false);
}

export function CompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'name': json['name'],
        'synonyms': json['synonyms'] == null ? undefined : json['synonyms'],
        'namesForPublication': json['namesForPublication'] == null ? undefined : ((json['namesForPublication'] as Array<any>).map(CompanyPublicationNameDtoFromJSON)),
        'emailAddresses': json['emailAddresses'] == null ? undefined : ((json['emailAddresses'] as Array<any>).map(CompanyEmailAddressDtoFromJSON)),
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(CompanyPhoneNumberDtoFromJSON)),
        'postalAddresses': json['postalAddresses'] == null ? undefined : ((json['postalAddresses'] as Array<any>).map(CompanyPostalAddressDtoFromJSON)),
        'companySections': json['companySections'] == null ? undefined : ((json['companySections'] as Array<any>).map(CompanySectionDtoFromJSON)),
        'countries': json['countries'] == null ? undefined : ((json['countries'] as Array<any>).map(CountryReferenceDtoFromJSON)),
        'fieldsOfInterest': json['fieldsOfInterest'] == null ? undefined : ((json['fieldsOfInterest'] as Array<any>).map(FieldOfInterestDtoFromJSON)),
        'shortDescription': json['shortDescription'] == null ? undefined : json['shortDescription'],
        'detailedDescription': json['detailedDescription'] == null ? undefined : json['detailedDescription'],
        'taxExemptions': ((json['taxExemptions'] as Array<any>).map(TaxExemptionDtoFromJSON)),
        'status': json['status'],
        'industries': json['industries'] == null ? undefined : ((json['industries'] as Array<any>).map(IndustryDtoFromJSON)),
        'filmographies': json['filmographies'] == null ? undefined : ((json['filmographies'] as Array<any>).map(FilmographyDtoFromJSON)),
        'logoMetadata': json['logoMetadata'] == null ? undefined : FileMetadataDtoFromJSON(json['logoMetadata']),
        'markers': json['markers'] == null ? undefined : ((json['markers'] as Array<any>).map(CompanyMarkerDtoFromJSON)),
        'historicMarkers': json['historicMarkers'] == null ? undefined : ((json['historicMarkers'] as Array<any>).map(CompanyMarkerDtoFromJSON)),
        'mergedInto': json['mergedInto'] == null ? undefined : CompanyReferenceDtoFromJSON(json['mergedInto']),
    };
}

export function CompanyDtoToJSON(value?: CompanyDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'name': value['name'],
        'synonyms': value['synonyms'],
        'namesForPublication': value['namesForPublication'] == null ? undefined : ((value['namesForPublication'] as Array<any>).map(CompanyPublicationNameDtoToJSON)),
        'emailAddresses': value['emailAddresses'] == null ? undefined : ((value['emailAddresses'] as Array<any>).map(CompanyEmailAddressDtoToJSON)),
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(CompanyPhoneNumberDtoToJSON)),
        'postalAddresses': value['postalAddresses'] == null ? undefined : ((value['postalAddresses'] as Array<any>).map(CompanyPostalAddressDtoToJSON)),
        'companySections': value['companySections'] == null ? undefined : ((value['companySections'] as Array<any>).map(CompanySectionDtoToJSON)),
        'countries': value['countries'] == null ? undefined : ((value['countries'] as Array<any>).map(CountryReferenceDtoToJSON)),
        'fieldsOfInterest': value['fieldsOfInterest'] == null ? undefined : ((value['fieldsOfInterest'] as Array<any>).map(FieldOfInterestDtoToJSON)),
        'shortDescription': value['shortDescription'],
        'detailedDescription': value['detailedDescription'],
        'taxExemptions': ((value['taxExemptions'] as Array<any>).map(TaxExemptionDtoToJSON)),
        'status': value['status'],
        'industries': value['industries'] == null ? undefined : ((value['industries'] as Array<any>).map(IndustryDtoToJSON)),
        'filmographies': value['filmographies'] == null ? undefined : ((value['filmographies'] as Array<any>).map(FilmographyDtoToJSON)),
        'logoMetadata': FileMetadataDtoToJSON(value['logoMetadata']),
        'markers': value['markers'] == null ? undefined : ((value['markers'] as Array<any>).map(CompanyMarkerDtoToJSON)),
        'historicMarkers': value['historicMarkers'] == null ? undefined : ((value['historicMarkers'] as Array<any>).map(CompanyMarkerDtoToJSON)),
        'mergedInto': CompanyReferenceDtoToJSON(value['mergedInto']),
    };
}

