/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { MailingReferenceDto } from './MailingReferenceDto';
import {
    MailingReferenceDtoFromJSON,
    MailingReferenceDtoFromJSONTyped,
    MailingReferenceDtoToJSON,
} from './MailingReferenceDto';
import type { ReportReferenceDto } from './ReportReferenceDto';
import {
    ReportReferenceDtoFromJSON,
    ReportReferenceDtoFromJSONTyped,
    ReportReferenceDtoToJSON,
} from './ReportReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface ListListItemDto
 */
export interface ListListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof ListListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ListListItemDto
     */
    name: string;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof ListListItemDto
     */
    owners: Array<SectionReferenceDto>;
    /**
     * 
     * @type {Array<SectionReferenceDto>}
     * @memberof ListListItemDto
     */
    coOwners: Array<SectionReferenceDto>;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof ListListItemDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {ReportReferenceDto}
     * @memberof ListListItemDto
     */
    report?: ReportReferenceDto;
    /**
     * 
     * @type {MailingReferenceDto}
     * @memberof ListListItemDto
     */
    mailing?: MailingReferenceDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof ListListItemDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the ListListItemDto interface.
 */
export function instanceOfListListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('owners' in value)) return false;
    if (!('coOwners' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function ListListItemDtoFromJSON(json: any): ListListItemDto {
    return ListListItemDtoFromJSONTyped(json, false);
}

export function ListListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'owners': ((json['owners'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'coOwners': ((json['coOwners'] as Array<any>).map(SectionReferenceDtoFromJSON)),
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'report': json['report'] == null ? undefined : ReportReferenceDtoFromJSON(json['report']),
        'mailing': json['mailing'] == null ? undefined : MailingReferenceDtoFromJSON(json['mailing']),
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function ListListItemDtoToJSON(value?: ListListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'owners': ((value['owners'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'coOwners': ((value['coOwners'] as Array<any>).map(SectionReferenceDtoToJSON)),
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'report': ReportReferenceDtoToJSON(value['report']),
        'mailing': MailingReferenceDtoToJSON(value['mailing']),
        'version': VersionDtoToJSON(value['version']),
    };
}

