import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import recipientApi from '../../../../data-access/recipient-api';
import FormControl from '../../../../ui/form/form-control';
import ValueAsyncSelect from '../../../../ui/select/value-async-select';
import { isListRecipientLink } from '../../mailing-recipient-util/list-recipient-link';
import { RecipientLinkDto } from '../../../../api';

export interface AddListRecipient {
  listRecipient: RecipientLinkDto;
}

export default function ListMailingRecipientControl({ mailingId }: { mailingId: string }) {
  const { field } = useController<AddListRecipient, 'listRecipient'>({
    name: 'listRecipient',
  });
  const { t } = useTranslation('mailing');

  return (
    <FormControl<AddListRecipient>
      label={t('recipients.list')}
      name="listRecipient"
      helperText={t('recipients.maxListRecipientsHelper', { count: 10 })}
    >
      <ValueAsyncSelect<RecipientLinkDto>
        {...field}
        value={field.value}
        loadOptions={async (value: string, size: number) => {
          const { content } = await recipientApi.fetchPossibleRecipients({
            id: mailingId,
            pageable: { size },
            q: value,
            recipientType: 'LIST',
          });
          return content;
        }}
        defaultOptions={false}
        renderLabel={(list) => isListRecipientLink(list) && list.linkedRecipient.name}
        optionIdentifier={(person) => person.id!}
      />
    </FormControl>
  );
}
