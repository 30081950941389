import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EditionDto,
  EditionReferenceDto,
  StandPersonRelationDto,
  StandPersonRelationTypeDto,
  StandReferenceDto,
} from '../../../api';
import standApi from '../../../data-access/stand-api';
import Label from '../../../ui/form/label';
import ValueAsyncSelectFormControl from '../../../ui/form/select-control/value-async-select-form-control';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import HelperPopover from '../../../ui/helper-buttons/helper-popover';
import { personIcon, standIcon } from '../../../ui/icons/business-objects';
import EditionSelect from '../../edition/edition-select/edition-select';
import useActiveEdition from '../../edition/use-active-edition/use-active-edition';
import ConnectionLine from '../../relations/common/connection-line';

interface PersonStandRelationEditorProps {
  fromName: string;
  initialFocusRef?: RefObject<HTMLElement>;
}

export default function PersonStandRelationEditor({ fromName, initialFocusRef }: PersonStandRelationEditorProps) {
  const { t } = useTranslation('stand');
  const activeEdition = useActiveEdition();
  const [selectedEdition, setEdition] = React.useState<EditionDto | EditionReferenceDto | null>(activeEdition);

  const { trigger, resetField } = useFormContext<StandPersonRelationDto>();

  const validateNoDuplicate = React.useCallback(
    async (stand: StandReferenceDto, formValues: StandPersonRelationDto) => {
      if (stand != null) {
        const existingRelations = await standApi.standPersonRelationExists({
          standId: stand.id,
          personId: formValues.person.id,
          relationType: formValues.relationType,
        });

        if (existingRelations) {
          return t('personRelation.exists');
        }
      }
      return true;
    },
    [t],
  );

  const loadOptions = async (searchValue: string, pageSizeLimit: number): Promise<StandReferenceDto[]> => {
    const page = await standApi.searchStandListItems({
      filter: [`name,contain,${searchValue}`, `edition.id,eq,${selectedEdition!.id}`],
      pageable: { size: pageSizeLimit, sort: ['name,ASC'] },
    });

    return page.content.map((stand) => ({
      id: stand.id!,
      name: stand.name,
      standNumber: stand.standNumber,
    }));
  };

  return (
    <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
      <Flex position="relative" align="center">
        <Box as={FontAwesomeIcon} icon={personIcon} fixedWidth flexShrink={0} />
        <Box ml={3}>
          <Text as="span" fontWeight="medium">
            {fromName}
          </Text>
        </Box>
      </Flex>
      <Flex position="relative" align="center">
        <ConnectionLine position="top" spacing={2} />
        <Box as={FontAwesomeIcon} icon={faLink} fixedWidth flexShrink={0} />
        <ConnectionLine position="bottom" spacing={2} />
        <Stack
          width="full"
          spacing={4}
          ml={3}
          border="solid 1px"
          borderColor="border.01"
          borderWidth={1}
          borderRadius={4}
          px={4}
          py={3}
        >
          <ValueSelectFormControl<StandPersonRelationTypeDto, StandPersonRelationDto>
            label={t('personRelation.relationType')}
            helperPopover={<HelperPopover children={t('personRelation.relationTypeHelp')} />}
            options={relationTypeOptions}
            renderLabel={(value) => t(`personRelation.relationTypeOptions.${value}`)}
            name="relationType"
            ref={initialFocusRef}
            isRequired
            onChange={() => {
              trigger('stand');
            }}
          />
          <Stack spacing={1}>
            <Label id="edition" isRequired>
              {t('edition')}
            </Label>
            <EditionSelect
              value={selectedEdition}
              onChange={(edition) => {
                setEdition(edition);
                resetField('stand');
              }}
            />
          </Stack>
        </Stack>
      </Flex>
      <Flex align="center" position="relative">
        <ConnectionLine position="top" showArrow spacing={2} />
        <Box as={FontAwesomeIcon} icon={standIcon} fixedWidth flexShrink={0} />
        <Box width="full" ml={3}>
          <ValueAsyncSelectFormControl<StandReferenceDto, StandPersonRelationDto, 'stand'>
            label={t('stand')}
            optionIdentifier={(stand) => stand.id}
            loadOptions={loadOptions}
            renderLabel={(option) => option.name}
            isRequired
            name="stand"
            rules={{ validate: validateNoDuplicate }}
          />
        </Box>
      </Flex>
    </Stack>
  );
}

const relationTypeOptions = [
  StandPersonRelationTypeDto.OTHER,
  StandPersonRelationTypeDto.STAND_COORDINATOR,
  StandPersonRelationTypeDto.STAND_PERSONNEL,
  StandPersonRelationTypeDto.EXTERNAL_CONTACT,
];
