import React from 'react';
import { useTranslation } from 'react-i18next';
import { MailingDtoStatusEnum, RecipientLinkDto } from '../../../api';
import { DataTableColumn } from '../../../ui/data-table';
import ExistsFilter from '../../../ui/data-table/filter/exists-filter';
import Optional from '../../../ui/optional/optional';
import useFetcher from '../../../util/swr/use-fetcher';
import ListReference from '../../list/list-reference/list-reference';
import { fetchMailing } from '../mailing-queries';
import { isListRecipientLink } from '../mailing-recipient-util/list-recipient-link';
import DeleteRecipientButton from './delete-recipient-button';

export default function useEmailRecipientColumns(mailingId: string) {
  const { t } = useTranslation('mailing');
  const mailing = useFetcher(fetchMailing, { id: mailingId });

  const columns: DataTableColumn<RecipientLinkDto>[] = React.useMemo(
    () => [
      {
        key: 'linkedRecipient.email',
        name: t('recipients.emails'),
        isSortable: true,
        renderCell: (recipientLink) => isListRecipientLink(recipientLink) && <Optional />,
      },
      {
        key: 'linkedRecipient.id',
        name: t('recipients.list'),
        isSortable: true,
        renderCell: (recipientLink) =>
          isListRecipientLink(recipientLink) && <ListReference list={recipientLink.linkedRecipient} />,
        filter: <ExistsFilter label={t('recipients.list')} />,
      },
      {
        key: 'action',
        sticky: true,
        renderCell: (recipientLink) =>
          isListRecipientLink(recipientLink) &&
          mailing.permissions.canEdit && (
            <DeleteRecipientButton
              mailingId={mailingId}
              recipientId={recipientLink.id}
              recipientType="LIST"
              isDisabled={mailing.status !== MailingDtoStatusEnum.DRAFT}
              displayName={recipientLink.linkedRecipient.name}
            />
          ),
      },
    ],
    [mailing.permissions.canEdit, mailing.status, mailingId, t],
  );
  return columns;
}
