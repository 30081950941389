import React from 'react';
import { groupTemplateIcon } from '../../ui/icons/business-objects';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { useGroupTemplatePermissionClassGroupExtension } from './group-template-permissions';
import GroupTemplateSearchResult from './group-template-search-result';

export default function GroupTemplatePluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'GROUP_TEMPLATE', Component: GroupTemplateSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'GROUP_TEMPLATE',
    icon: groupTemplateIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.GROUP_TEMPLATE" />,
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useGroupTemplatePermissionClassGroupExtension);
}
