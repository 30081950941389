/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Reference of a GuestList
 * @export
 * @interface GuestListReferenceDto
 */
export interface GuestListReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof GuestListReferenceDto
     */
    id: string;
    /**
     * The status of the guest list, whether it is active or inactive
     * @type {boolean}
     * @memberof GuestListReferenceDto
     */
    active: boolean;
}

/**
 * Check if a given object implements the GuestListReferenceDto interface.
 */
export function instanceOfGuestListReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('active' in value)) return false;
    return true;
}

export function GuestListReferenceDtoFromJSON(json: any): GuestListReferenceDto {
    return GuestListReferenceDtoFromJSONTyped(json, false);
}

export function GuestListReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestListReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'active': json['active'],
    };
}

export function GuestListReferenceDtoToJSON(value?: GuestListReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'active': value['active'],
    };
}

