/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccessGroupDto,
  AccessGroupPageDto,
  AccessGroupReferencePageDto,
  FailureDto,
  HistoryPageDto,
  PageableDto,
} from '../models/index';
import {
    AccessGroupDtoFromJSON,
    AccessGroupDtoToJSON,
    AccessGroupPageDtoFromJSON,
    AccessGroupPageDtoToJSON,
    AccessGroupReferencePageDtoFromJSON,
    AccessGroupReferencePageDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface ActivateAccessGroupRequest {
    id: string;
}

export interface CreateAccessGroupRequest {
    accessGroupDto: AccessGroupDto;
}

export interface DeactivateAccessGroupRequest {
    id: string;
}

export interface FetchAccessGroupRequest {
    id: string;
}

export interface FetchAccessGroupHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface SearchAccessGroupListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchActiveAccessGroupReferencesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateAccessGroupRequest {
    id: string;
    accessGroupDto: AccessGroupDto;
}

/**
 * 
 */
export class AccessGroupApi extends runtime.BaseAPI {

    /**
     * Activate the access group
     */
    async activateAccessGroupRaw(requestParameters: ActivateAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling activateAccessGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access-groups/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate the access group
     */
    async activateAccessGroup(requestParameters: ActivateAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.activateAccessGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new access group
     */
    async createAccessGroupRaw(requestParameters: CreateAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessGroupDto>> {
        if (requestParameters['accessGroupDto'] == null) {
            throw new runtime.RequiredError(
                'accessGroupDto',
                'Required parameter "accessGroupDto" was null or undefined when calling createAccessGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccessGroupDtoToJSON(requestParameters['accessGroupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessGroupDtoFromJSON(jsonValue));
    }

    /**
     * Create a new access group
     */
    async createAccessGroup(requestParameters: CreateAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessGroupDto> {
        const response = await this.createAccessGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deactivate the access group
     */
    async deactivateAccessGroupRaw(requestParameters: DeactivateAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deactivateAccessGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access-groups/{id}/deactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivate the access group
     */
    async deactivateAccessGroup(requestParameters: DeactivateAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deactivateAccessGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Get the access group business object.
     */
    async fetchAccessGroupRaw(requestParameters: FetchAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessGroupDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchAccessGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessGroupDtoFromJSON(jsonValue));
    }

    /**
     * Get the access group business object.
     */
    async fetchAccessGroup(requestParameters: FetchAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessGroupDto> {
        const response = await this.fetchAccessGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the access group.
     */
    async fetchAccessGroupHistoryRaw(requestParameters: FetchAccessGroupHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchAccessGroupHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access-groups/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the access group.
     */
    async fetchAccessGroupHistory(requestParameters: FetchAccessGroupHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchAccessGroupHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for access groups.
     */
    async searchAccessGroupListItemsRaw(requestParameters: SearchAccessGroupListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessGroupPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessGroupPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for access groups.
     */
    async searchAccessGroupListItems(requestParameters: SearchAccessGroupListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessGroupPageDto> {
        const response = await this.searchAccessGroupListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for active access group references.
     */
    async searchActiveAccessGroupReferencesRaw(requestParameters: SearchActiveAccessGroupReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessGroupReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access-groups/access-group-references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessGroupReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for active access group references.
     */
    async searchActiveAccessGroupReferences(requestParameters: SearchActiveAccessGroupReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessGroupReferencePageDto> {
        const response = await this.searchActiveAccessGroupReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new access group.
     */
    async templateAccessGroupRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessGroupDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access-groups/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessGroupDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new access group.
     */
    async templateAccessGroup(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessGroupDto> {
        const response = await this.templateAccessGroupRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing access group.
     */
    async updateAccessGroupRaw(requestParameters: UpdateAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessGroupDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateAccessGroup().'
            );
        }

        if (requestParameters['accessGroupDto'] == null) {
            throw new runtime.RequiredError(
                'accessGroupDto',
                'Required parameter "accessGroupDto" was null or undefined when calling updateAccessGroup().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccessGroupDtoToJSON(requestParameters['accessGroupDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccessGroupDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing access group.
     */
    async updateAccessGroup(requestParameters: UpdateAccessGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessGroupDto> {
        const response = await this.updateAccessGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
