/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AccessGroupPermissionDto } from './AccessGroupPermissionDto';
import {
    instanceOfAccessGroupPermissionDto,
    AccessGroupPermissionDtoFromJSON,
    AccessGroupPermissionDtoFromJSONTyped,
    AccessGroupPermissionDtoToJSON,
} from './AccessGroupPermissionDto';
import type { AccountPermissionDto } from './AccountPermissionDto';
import {
    instanceOfAccountPermissionDto,
    AccountPermissionDtoFromJSON,
    AccountPermissionDtoFromJSONTyped,
    AccountPermissionDtoToJSON,
} from './AccountPermissionDto';
import type { AdminRolePermissionDto } from './AdminRolePermissionDto';
import {
    instanceOfAdminRolePermissionDto,
    AdminRolePermissionDtoFromJSON,
    AdminRolePermissionDtoFromJSONTyped,
    AdminRolePermissionDtoToJSON,
} from './AdminRolePermissionDto';
import type { AwardPermissionDto } from './AwardPermissionDto';
import {
    instanceOfAwardPermissionDto,
    AwardPermissionDtoFromJSON,
    AwardPermissionDtoFromJSONTyped,
    AwardPermissionDtoToJSON,
} from './AwardPermissionDto';
import type { AwardWinnerPermissionDto } from './AwardWinnerPermissionDto';
import {
    instanceOfAwardWinnerPermissionDto,
    AwardWinnerPermissionDtoFromJSON,
    AwardWinnerPermissionDtoFromJSONTyped,
    AwardWinnerPermissionDtoToJSON,
} from './AwardWinnerPermissionDto';
import type { CodePermissionDto } from './CodePermissionDto';
import {
    instanceOfCodePermissionDto,
    CodePermissionDtoFromJSON,
    CodePermissionDtoFromJSONTyped,
    CodePermissionDtoToJSON,
} from './CodePermissionDto';
import type { CompanyPermissionDto } from './CompanyPermissionDto';
import {
    instanceOfCompanyPermissionDto,
    CompanyPermissionDtoFromJSON,
    CompanyPermissionDtoFromJSONTyped,
    CompanyPermissionDtoToJSON,
} from './CompanyPermissionDto';
import type { CompanyStandPermissionDto } from './CompanyStandPermissionDto';
import {
    instanceOfCompanyStandPermissionDto,
    CompanyStandPermissionDtoFromJSON,
    CompanyStandPermissionDtoFromJSONTyped,
    CompanyStandPermissionDtoToJSON,
} from './CompanyStandPermissionDto';
import type { CompanyTagPermissionDto } from './CompanyTagPermissionDto';
import {
    instanceOfCompanyTagPermissionDto,
    CompanyTagPermissionDtoFromJSON,
    CompanyTagPermissionDtoFromJSONTyped,
    CompanyTagPermissionDtoToJSON,
} from './CompanyTagPermissionDto';
import type { EventTagPermissionDto } from './EventTagPermissionDto';
import {
    instanceOfEventTagPermissionDto,
    EventTagPermissionDtoFromJSON,
    EventTagPermissionDtoFromJSONTyped,
    EventTagPermissionDtoToJSON,
} from './EventTagPermissionDto';
import type { FormatCompanyPermissionDto } from './FormatCompanyPermissionDto';
import {
    instanceOfFormatCompanyPermissionDto,
    FormatCompanyPermissionDtoFromJSON,
    FormatCompanyPermissionDtoFromJSONTyped,
    FormatCompanyPermissionDtoToJSON,
} from './FormatCompanyPermissionDto';
import type { FormatPermissionDto } from './FormatPermissionDto';
import {
    instanceOfFormatPermissionDto,
    FormatPermissionDtoFromJSON,
    FormatPermissionDtoFromJSONTyped,
    FormatPermissionDtoToJSON,
} from './FormatPermissionDto';
import type { FormatTagPermissionDto } from './FormatTagPermissionDto';
import {
    instanceOfFormatTagPermissionDto,
    FormatTagPermissionDtoFromJSON,
    FormatTagPermissionDtoFromJSONTyped,
    FormatTagPermissionDtoToJSON,
} from './FormatTagPermissionDto';
import type { GroupTemplatePermissionDto } from './GroupTemplatePermissionDto';
import {
    instanceOfGroupTemplatePermissionDto,
    GroupTemplatePermissionDtoFromJSON,
    GroupTemplatePermissionDtoFromJSONTyped,
    GroupTemplatePermissionDtoToJSON,
} from './GroupTemplatePermissionDto';
import type { ListPermissionDto } from './ListPermissionDto';
import {
    instanceOfListPermissionDto,
    ListPermissionDtoFromJSON,
    ListPermissionDtoFromJSONTyped,
    ListPermissionDtoToJSON,
} from './ListPermissionDto';
import type { LocationPermissionDto } from './LocationPermissionDto';
import {
    instanceOfLocationPermissionDto,
    LocationPermissionDtoFromJSON,
    LocationPermissionDtoFromJSONTyped,
    LocationPermissionDtoToJSON,
} from './LocationPermissionDto';
import type { LocationTagPermissionDto } from './LocationTagPermissionDto';
import {
    instanceOfLocationTagPermissionDto,
    LocationTagPermissionDtoFromJSON,
    LocationTagPermissionDtoFromJSONTyped,
    LocationTagPermissionDtoToJSON,
} from './LocationTagPermissionDto';
import type { MailingBlocklistPermissionDto } from './MailingBlocklistPermissionDto';
import {
    instanceOfMailingBlocklistPermissionDto,
    MailingBlocklistPermissionDtoFromJSON,
    MailingBlocklistPermissionDtoFromJSONTyped,
    MailingBlocklistPermissionDtoToJSON,
} from './MailingBlocklistPermissionDto';
import type { MailingTemplatePermissionDto } from './MailingTemplatePermissionDto';
import {
    instanceOfMailingTemplatePermissionDto,
    MailingTemplatePermissionDtoFromJSON,
    MailingTemplatePermissionDtoFromJSONTyped,
    MailingTemplatePermissionDtoToJSON,
} from './MailingTemplatePermissionDto';
import type { MyAccountPermissionDto } from './MyAccountPermissionDto';
import {
    instanceOfMyAccountPermissionDto,
    MyAccountPermissionDtoFromJSON,
    MyAccountPermissionDtoFromJSONTyped,
    MyAccountPermissionDtoToJSON,
} from './MyAccountPermissionDto';
import type { OrderConfirmationPermissionDto } from './OrderConfirmationPermissionDto';
import {
    instanceOfOrderConfirmationPermissionDto,
    OrderConfirmationPermissionDtoFromJSON,
    OrderConfirmationPermissionDtoFromJSONTyped,
    OrderConfirmationPermissionDtoToJSON,
} from './OrderConfirmationPermissionDto';
import type { ParticipationPermissionDto } from './ParticipationPermissionDto';
import {
    instanceOfParticipationPermissionDto,
    ParticipationPermissionDtoFromJSON,
    ParticipationPermissionDtoFromJSONTyped,
    ParticipationPermissionDtoToJSON,
} from './ParticipationPermissionDto';
import type { PermissionClassGroupPermissionDto } from './PermissionClassGroupPermissionDto';
import {
    instanceOfPermissionClassGroupPermissionDto,
    PermissionClassGroupPermissionDtoFromJSON,
    PermissionClassGroupPermissionDtoFromJSONTyped,
    PermissionClassGroupPermissionDtoToJSON,
} from './PermissionClassGroupPermissionDto';
import type { PersonAccessGroupPermissionDto } from './PersonAccessGroupPermissionDto';
import {
    instanceOfPersonAccessGroupPermissionDto,
    PersonAccessGroupPermissionDtoFromJSON,
    PersonAccessGroupPermissionDtoFromJSONTyped,
    PersonAccessGroupPermissionDtoToJSON,
} from './PersonAccessGroupPermissionDto';
import type { PersonPermissionDto } from './PersonPermissionDto';
import {
    instanceOfPersonPermissionDto,
    PersonPermissionDtoFromJSON,
    PersonPermissionDtoFromJSONTyped,
    PersonPermissionDtoToJSON,
} from './PersonPermissionDto';
import type { PersonStandPermissionDto } from './PersonStandPermissionDto';
import {
    instanceOfPersonStandPermissionDto,
    PersonStandPermissionDtoFromJSON,
    PersonStandPermissionDtoFromJSONTyped,
    PersonStandPermissionDtoToJSON,
} from './PersonStandPermissionDto';
import type { PersonTagPermissionDto } from './PersonTagPermissionDto';
import {
    instanceOfPersonTagPermissionDto,
    PersonTagPermissionDtoFromJSON,
    PersonTagPermissionDtoFromJSONTyped,
    PersonTagPermissionDtoToJSON,
} from './PersonTagPermissionDto';
import type { ProductTagPermissionDto } from './ProductTagPermissionDto';
import {
    instanceOfProductTagPermissionDto,
    ProductTagPermissionDtoFromJSON,
    ProductTagPermissionDtoFromJSONTyped,
    ProductTagPermissionDtoToJSON,
} from './ProductTagPermissionDto';
import type { SectionPermissionDto } from './SectionPermissionDto';
import {
    instanceOfSectionPermissionDto,
    SectionPermissionDtoFromJSON,
    SectionPermissionDtoFromJSONTyped,
    SectionPermissionDtoToJSON,
} from './SectionPermissionDto';
import type { StaffPermissionDto } from './StaffPermissionDto';
import {
    instanceOfStaffPermissionDto,
    StaffPermissionDtoFromJSON,
    StaffPermissionDtoFromJSONTyped,
    StaffPermissionDtoToJSON,
} from './StaffPermissionDto';
import type { StaffRolePermissionDto } from './StaffRolePermissionDto';
import {
    instanceOfStaffRolePermissionDto,
    StaffRolePermissionDtoFromJSON,
    StaffRolePermissionDtoFromJSONTyped,
    StaffRolePermissionDtoToJSON,
} from './StaffRolePermissionDto';
import type { StandPermissionDto } from './StandPermissionDto';
import {
    instanceOfStandPermissionDto,
    StandPermissionDtoFromJSON,
    StandPermissionDtoFromJSONTyped,
    StandPermissionDtoToJSON,
} from './StandPermissionDto';
import type { StandTagPermissionDto } from './StandTagPermissionDto';
import {
    instanceOfStandTagPermissionDto,
    StandTagPermissionDtoFromJSON,
    StandTagPermissionDtoFromJSONTyped,
    StandTagPermissionDtoToJSON,
} from './StandTagPermissionDto';
import type { TagPermissionDto } from './TagPermissionDto';
import {
    instanceOfTagPermissionDto,
    TagPermissionDtoFromJSON,
    TagPermissionDtoFromJSONTyped,
    TagPermissionDtoToJSON,
} from './TagPermissionDto';

/**
 * @type PermissionDto
 * 
 * @export
 */
export type PermissionDto = { name: 'ACCESS_GROUP' } & AccessGroupPermissionDto | { name: 'ACCESS_GROUP-PERSON' } & PersonAccessGroupPermissionDto | { name: 'ACCOUNT' } & AccountPermissionDto | { name: 'ADMIN_ROLE' } & AdminRolePermissionDto | { name: 'AWARD' } & AwardPermissionDto | { name: 'AWARD-PERSON' } & AwardWinnerPermissionDto | { name: 'CODE' } & CodePermissionDto | { name: 'COMPANY' } & CompanyPermissionDto | { name: 'COMPANY-FORMAT' } & FormatCompanyPermissionDto | { name: 'COMPANY-STAND' } & CompanyStandPermissionDto | { name: 'COMPANY-TAG' } & CompanyTagPermissionDto | { name: 'EVENT-TAG' } & EventTagPermissionDto | { name: 'FORMAT' } & FormatPermissionDto | { name: 'FORMAT-TAG' } & FormatTagPermissionDto | { name: 'GROUP_TEMPLATE' } & GroupTemplatePermissionDto | { name: 'LIST' } & ListPermissionDto | { name: 'LOCATION' } & LocationPermissionDto | { name: 'LOCATION-TAG' } & LocationTagPermissionDto | { name: 'MAILING_BLOCKLIST' } & MailingBlocklistPermissionDto | { name: 'MAILING_TEMPLATE' } & MailingTemplatePermissionDto | { name: 'MY_ACCOUNT' } & MyAccountPermissionDto | { name: 'ORDER_CONFIRMATION' } & OrderConfirmationPermissionDto | { name: 'PARTICIPATION' } & ParticipationPermissionDto | { name: 'PERMISSION_CLASS_GROUP' } & PermissionClassGroupPermissionDto | { name: 'PERSON' } & PersonPermissionDto | { name: 'PERSON-STAND' } & PersonStandPermissionDto | { name: 'PERSON-TAG' } & PersonTagPermissionDto | { name: 'PRODUCT-TAG' } & ProductTagPermissionDto | { name: 'SECTION' } & SectionPermissionDto | { name: 'STAFF' } & StaffPermissionDto | { name: 'STAFF_ROLE' } & StaffRolePermissionDto | { name: 'STAND' } & StandPermissionDto | { name: 'STAND-TAG' } & StandTagPermissionDto | { name: 'TAG' } & TagPermissionDto;

export function PermissionDtoFromJSON(json: any): PermissionDto {
    return PermissionDtoFromJSONTyped(json, false);
}

export function PermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionDto {
    if (json == null) {
        return json;
    }
    switch (json['name']) {
        case 'ACCESS_GROUP':
            return {...AccessGroupPermissionDtoFromJSONTyped(json, true), name: 'ACCESS_GROUP'};
        case 'ACCESS_GROUP-PERSON':
            return {...PersonAccessGroupPermissionDtoFromJSONTyped(json, true), name: 'ACCESS_GROUP-PERSON'};
        case 'ACCOUNT':
            return {...AccountPermissionDtoFromJSONTyped(json, true), name: 'ACCOUNT'};
        case 'ADMIN_ROLE':
            return {...AdminRolePermissionDtoFromJSONTyped(json, true), name: 'ADMIN_ROLE'};
        case 'AWARD':
            return {...AwardPermissionDtoFromJSONTyped(json, true), name: 'AWARD'};
        case 'AWARD-PERSON':
            return {...AwardWinnerPermissionDtoFromJSONTyped(json, true), name: 'AWARD-PERSON'};
        case 'CODE':
            return {...CodePermissionDtoFromJSONTyped(json, true), name: 'CODE'};
        case 'COMPANY':
            return {...CompanyPermissionDtoFromJSONTyped(json, true), name: 'COMPANY'};
        case 'COMPANY-FORMAT':
            return {...FormatCompanyPermissionDtoFromJSONTyped(json, true), name: 'COMPANY-FORMAT'};
        case 'COMPANY-STAND':
            return {...CompanyStandPermissionDtoFromJSONTyped(json, true), name: 'COMPANY-STAND'};
        case 'COMPANY-TAG':
            return {...CompanyTagPermissionDtoFromJSONTyped(json, true), name: 'COMPANY-TAG'};
        case 'EVENT-TAG':
            return {...EventTagPermissionDtoFromJSONTyped(json, true), name: 'EVENT-TAG'};
        case 'FORMAT':
            return {...FormatPermissionDtoFromJSONTyped(json, true), name: 'FORMAT'};
        case 'FORMAT-TAG':
            return {...FormatTagPermissionDtoFromJSONTyped(json, true), name: 'FORMAT-TAG'};
        case 'GROUP_TEMPLATE':
            return {...GroupTemplatePermissionDtoFromJSONTyped(json, true), name: 'GROUP_TEMPLATE'};
        case 'LIST':
            return {...ListPermissionDtoFromJSONTyped(json, true), name: 'LIST'};
        case 'LOCATION':
            return {...LocationPermissionDtoFromJSONTyped(json, true), name: 'LOCATION'};
        case 'LOCATION-TAG':
            return {...LocationTagPermissionDtoFromJSONTyped(json, true), name: 'LOCATION-TAG'};
        case 'MAILING_BLOCKLIST':
            return {...MailingBlocklistPermissionDtoFromJSONTyped(json, true), name: 'MAILING_BLOCKLIST'};
        case 'MAILING_TEMPLATE':
            return {...MailingTemplatePermissionDtoFromJSONTyped(json, true), name: 'MAILING_TEMPLATE'};
        case 'MY_ACCOUNT':
            return {...MyAccountPermissionDtoFromJSONTyped(json, true), name: 'MY_ACCOUNT'};
        case 'ORDER_CONFIRMATION':
            return {...OrderConfirmationPermissionDtoFromJSONTyped(json, true), name: 'ORDER_CONFIRMATION'};
        case 'PARTICIPATION':
            return {...ParticipationPermissionDtoFromJSONTyped(json, true), name: 'PARTICIPATION'};
        case 'PERMISSION_CLASS_GROUP':
            return {...PermissionClassGroupPermissionDtoFromJSONTyped(json, true), name: 'PERMISSION_CLASS_GROUP'};
        case 'PERSON':
            return {...PersonPermissionDtoFromJSONTyped(json, true), name: 'PERSON'};
        case 'PERSON-STAND':
            return {...PersonStandPermissionDtoFromJSONTyped(json, true), name: 'PERSON-STAND'};
        case 'PERSON-TAG':
            return {...PersonTagPermissionDtoFromJSONTyped(json, true), name: 'PERSON-TAG'};
        case 'PRODUCT-TAG':
            return {...ProductTagPermissionDtoFromJSONTyped(json, true), name: 'PRODUCT-TAG'};
        case 'SECTION':
            return {...SectionPermissionDtoFromJSONTyped(json, true), name: 'SECTION'};
        case 'STAFF':
            return {...StaffPermissionDtoFromJSONTyped(json, true), name: 'STAFF'};
        case 'STAFF_ROLE':
            return {...StaffRolePermissionDtoFromJSONTyped(json, true), name: 'STAFF_ROLE'};
        case 'STAND':
            return {...StandPermissionDtoFromJSONTyped(json, true), name: 'STAND'};
        case 'STAND-TAG':
            return {...StandTagPermissionDtoFromJSONTyped(json, true), name: 'STAND-TAG'};
        case 'TAG':
            return {...TagPermissionDtoFromJSONTyped(json, true), name: 'TAG'};
        default:
            throw new Error(`No variant of PermissionDto exists with 'name=${json['name']}'`);
    }
}

export function PermissionDtoToJSON(value?: PermissionDto | null): any {
    if (value == null) {
        return value;
    }
    switch (value['name']) {
        case 'ACCESS_GROUP':
            return AccessGroupPermissionDtoToJSON(value);
        case 'ACCESS_GROUP-PERSON':
            return PersonAccessGroupPermissionDtoToJSON(value);
        case 'ACCOUNT':
            return AccountPermissionDtoToJSON(value);
        case 'ADMIN_ROLE':
            return AdminRolePermissionDtoToJSON(value);
        case 'AWARD':
            return AwardPermissionDtoToJSON(value);
        case 'AWARD-PERSON':
            return AwardWinnerPermissionDtoToJSON(value);
        case 'CODE':
            return CodePermissionDtoToJSON(value);
        case 'COMPANY':
            return CompanyPermissionDtoToJSON(value);
        case 'COMPANY-FORMAT':
            return FormatCompanyPermissionDtoToJSON(value);
        case 'COMPANY-STAND':
            return CompanyStandPermissionDtoToJSON(value);
        case 'COMPANY-TAG':
            return CompanyTagPermissionDtoToJSON(value);
        case 'EVENT-TAG':
            return EventTagPermissionDtoToJSON(value);
        case 'FORMAT':
            return FormatPermissionDtoToJSON(value);
        case 'FORMAT-TAG':
            return FormatTagPermissionDtoToJSON(value);
        case 'GROUP_TEMPLATE':
            return GroupTemplatePermissionDtoToJSON(value);
        case 'LIST':
            return ListPermissionDtoToJSON(value);
        case 'LOCATION':
            return LocationPermissionDtoToJSON(value);
        case 'LOCATION-TAG':
            return LocationTagPermissionDtoToJSON(value);
        case 'MAILING_BLOCKLIST':
            return MailingBlocklistPermissionDtoToJSON(value);
        case 'MAILING_TEMPLATE':
            return MailingTemplatePermissionDtoToJSON(value);
        case 'MY_ACCOUNT':
            return MyAccountPermissionDtoToJSON(value);
        case 'ORDER_CONFIRMATION':
            return OrderConfirmationPermissionDtoToJSON(value);
        case 'PARTICIPATION':
            return ParticipationPermissionDtoToJSON(value);
        case 'PERMISSION_CLASS_GROUP':
            return PermissionClassGroupPermissionDtoToJSON(value);
        case 'PERSON':
            return PersonPermissionDtoToJSON(value);
        case 'PERSON-STAND':
            return PersonStandPermissionDtoToJSON(value);
        case 'PERSON-TAG':
            return PersonTagPermissionDtoToJSON(value);
        case 'PRODUCT-TAG':
            return ProductTagPermissionDtoToJSON(value);
        case 'SECTION':
            return SectionPermissionDtoToJSON(value);
        case 'STAFF':
            return StaffPermissionDtoToJSON(value);
        case 'STAFF_ROLE':
            return StaffRolePermissionDtoToJSON(value);
        case 'STAND':
            return StandPermissionDtoToJSON(value);
        case 'STAND-TAG':
            return StandTagPermissionDtoToJSON(value);
        case 'TAG':
            return TagPermissionDtoToJSON(value);
        default:
            throw new Error(`No variant of PermissionDto exists with 'name=${value['name']}'`);
    }

}

