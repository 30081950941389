import { CompanyReferenceDto } from '../../../api';
import { companyIcon } from '../../../ui/icons/business-objects';
import Optional from '../../../ui/optional/optional';
import Reference, { ReferenceProps } from '../../../ui/reference/reference';
import HasPermission from '../../permission/has-permission';

export interface CompanyReferenceProps extends Omit<ReferenceProps, 'icon' | 'to' | 'children'> {
  companyReference: CompanyReferenceDto | undefined;
}

export default function CompanyReference({ companyReference, ...props }: CompanyReferenceProps) {
  return (
    <Optional>
      {companyReference?.name && (
        <HasPermission necessaryPermission="COMPANY:CAN_SEE_DETAILS" fallback={companyReference.name}>
          <Reference icon={companyIcon} to={'/companies/' + companyReference.id} {...props}>
            {companyReference.name}
          </Reference>
        </HasPermission>
      )}
    </Optional>
  );
}
